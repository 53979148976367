<template>
  <div :class="'home ' + (noticeShow ? 'glo-magbot' : 'glo-magbot0')">
    <div class="banner glo-relative">
        <img v-if="projectCode == 'nfeq'" src="@/assets/bridge/img/item-nfeq.jpg">
        <img v-else src="@/assets/bridge/img/item-img.jpg">
        <div class="title">智慧桥梁健康监测系统</div>
    </div>
    <div class="relative chart-content">
      <van-circle v-model="currentRate" layer-color="#e2e2e2" :rate="healthVal || 0" :speed="100" :color="healthVal < 45 ? grad_subHealth : grad_health" :stroke-width="65" size="180px">
            <div class="glo-inner-align">
        <transition name="circle-text">
              <div v-if="!time" key="text1">
                  <div class="time">{{nowTime}}</div>
                  <div class="tag">北京时间</div>
              </div>
              <div v-else key="text2">
                  <div class="time">{{healthVal || 0}}</div>
                  <div class="tag">综合评估</div>
              </div>
        </transition>
            </div>
      </van-circle>
    </div>
    <div class="show-status">
      <van-row>
        <van-col span="12" class="border-right right"><i :class="{'fa fa-heartbeat':true,'fa-active':time}" @click="time = true"></i></van-col>
        <van-col span="12" class="left"><i :class="{'fa fa-clock-o':true,'fa-active':!time}" @click="time = false"></i></van-col>
      </van-row>
    </div>
    <div class="weather">
      <div class="content">
        <van-row>
            <van-col span="12">
              <!-- <img :src="require('@/assets/bridge/img/weather/'+weather.icon+'.png')" class="weather-icon"> -->
              <i :class="`qi-${weather.icon} weather-icon`"></i>
            </van-col>
            <van-col span="12" class="weather-text">
              <div><span class="tep">{{weather.temp || 0}}°</span> <span>{{weather.text || '多云'}}</span></div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="8">
                <div><i class="iconfont icon-iconset0469"></i></div>
                <div class="text">{{weather.windSpeed || 0}} km/h</div>
            </van-col>
            <van-col span="8">
                <div><i class="iconfont icon-shidu"></i></div>
                <div class="text">{{weather.humidity || 0}}%</div>
            </van-col>
            <van-col span="8">
                <div><i class="iconfont icon-jiangyuliang"></i></div>
                <div class="text">{{weather.precip || 0}} mm</div>
            </van-col>
        </van-row>
        <div class="update-time">更新时间：{{weather.updateTime}}</div>
      </div>
    </div>  
    <tabbar />
  </div>
</template>

<script>
import tabbar from '@/components/bridge/NFtabbar.vue'

export default {
  name: 'Home',
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        time:true,
        nowTime:'00:00',
        currentRate:0,
        grad_subHealth: {
          '0%': '#8ae7d0',
          '100%': '#1E90FF',
        },
        grad_health:{
          '0%': '#1E90FF',
          '100%': '#0BD1C0',
        },
        bridgeCode:'',
        healthVal:'',
        magbotVal:'',
        weather:{
          icon:'104',
          updateTime:'2020-01-01T00:00+00:00'
        },
        downTime:null
    }
  },
  // 接收父组件的值
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 获取当前时间
    getNowTime(){
      let time = new Date();
      return `${time.getHours()}:${time.getMinutes()<10 ? '0'+time.getMinutes() : time.getMinutes()}`
    },
    // 获取桥梁健康值
    healthInfo(){
      this.$api.NEWBRIDGE.getHealth({
        projectCode:this.projectCode
      }).then( d=>{
        this.healthVal = d.briHealth *10;
      })
    },
    // 获取天气预报信息
    Weather(){
      this.$api.BRIDGE.getWeather({
        projectCode:this.projectCode
      }).then( d => {
          this.weather = {...d.now};
          this.weather.updateTime = d.updateTime
      })
    }
  },
  mounted(){
    this.projectCode = window.sessionStorage.getItem('projectCode');
    if(this.projectCode){
      this.healthInfo();
      this.Weather();
    }
    // 更新当前时间
    this.downTime = setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
  beforeDestroy(){
    this.downTime ? clearInterval(this.downTime) : void 0;
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
}
</script>

<style lang="scss" scoped>
.banner{
  img{
    width: 100%;
    height: 12rem;
  }
  .title{
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    color: #33969c;
    padding-left: 5%;
    padding: 4% 0 0 5%;
    text-shadow: 4px 2px 1px #dedada;
    letter-spacing: 4px;
  }
}
.chart-content{
    padding-top: 8%;
    .time{
      font-size: 2.5rem;
      color: rgb(92, 75, 66);
      margin-bottom: 4%;
      font-weight: bold;
    }
    .tag{color:#a5a5a5;letter-spacing: 2px;font-size: .85rem;}
}
.flex{display: flex;justify-content: left;align-self: center;padding-left: 25%;}
.show-status{
  margin: 4%;
  font-size: 2.4rem;color:#a5a5a5;
  .right{text-align: right;padding-right: 5%;}
  .left{text-align: left;padding-left: 5%;}
  .border-right{border-right: 2px solid rgb(231, 231, 231);}
  .fa-active{color: #0eb9ab;}
}
.weather{
  padding: 2% 4%;
  .content{
    padding: 4%;
    background-color: #c6f5e8;
    border-radius: 15px;
    box-shadow: 3px 1px 5px 0px #b1b1b1;
    .weather-icon{
      font-size: 4rem;
      color: #0089ff;
    }
    .weather-text{
        font-size: .95rem;
        line-height: 2;
        white-space: nowrap;
        .tep{font-size: 2.4rem;font-weight: bold;}
    }
    .iconfont{font-size: 1.5rem;color: #2faddf;line-height: 1;}
    .text{font-size: .85rem;margin-top: 4%;color: #737373;}
    .icon-iconset0469{color: #650c0c;}
    .icon-shidu{color: #d46d6d;}
    .icon-jiangyuliang{color: #e06d18;}
    .update-time{font-size: .5rem;color: #9a9a9a;text-align: right;padding-top: 4%;}
  }
}
.circle-text{
  position: fixed;left:0;right: 0;
  width: 100%;
}
.circle-text-enter, .circle-text-leave-to
{
  left:0;top: 0;right: 0;
  position: absolute;
  transform:translateX(0px) translateY(0px) scale(0.2);
  opacity:1;
}
.circle-text-enter-active {
  transition: all 0.6s ease;
}
.circle-text-leave-active {
  transition: all .1s ease;
  transform:translateX(0) translateY(0) scale(0) ;
  opacity: 0;
  z-index: 100;
}
</style>